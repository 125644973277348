<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div class="go-back" @click="$router.push({name: 'processes'})"><icon-arrow-back v-if="$resize && $mq.above(780)"/></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{process.id ? $t('processes.update_process') :  $t('processes.add_new_process') }}</h1>
				<div class="action">
					<v-popover class="help" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
						<a class="btn-tbf blue only-icon " :href="helpData.link" target="_blank" v-if="helpData">
							<div class="icon"><icon-question /></div>
						</a>

						<template slot="popover">
							<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
						</template>
					</v-popover>

					<button class="btn-tbf white only-icon" @click="$router.push({name: 'processes'})"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{$t('processes.new_process_title')}}</div>
					<div class="description">{{$t('processes.process_description')}}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{$t('processes.name')}}*</label>
							<div v-if="$v.process.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.process.name.$error}">
							<div class="icon-left"><icon-chart /></div>
							<div class="icon-right" v-if="process.name != ''" @click.stop="process.name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('processes.name_placeholder')" class="input-text" v-model="process.name">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{$t('processes.manager')}}*</label>
							<div v-if="$v.selectedUser.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedUser.$error}">
							<div class="icon-left"><icon-people /></div>
							<multiselect 
							v-model="selectedUser"
							:options="users"
							:allow-empty="false"
							:show-labels="false"
							:multiple="false" 
							:close-on-select="true"
							track-by="id"
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{$t('procedures.choose_manager')}}
									</span>
								</template>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								<template slot="option" slot-scope="props">{{ props.option.name }}</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('processes.description')}}</label>
						</div>
						<div class="input-box">
							<textarea :placeholder="$t('processes.description_placeholder')" class="input-text" v-model="process.description"></textarea>
						</div>
					</div>
					
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{$t('procedures.triggers')}}</div>
					<div class="description">{{$t('processes.triggers_description')}}</div>
				</div>
				<div class="form">
					<div class="saved-skills" v-if="savedTriggers.length">
						<div class="box">
							<div class="group-skill" v-for="(trigger,index) in savedTriggers">
								<div class="main-label">
									<div class="name">{{ trigger.type == 'weekly' || trigger.type == 'yearly' ? $tc('trigger.text.' + trigger.type, trigger.value.split(",").length ) : $t('trigger.text.' + trigger.type) }}</div>
									<div class="actions">
										<button class="btn-tbf white" @click="editTrigger(trigger, index)"><icon-edit /></button>
										<button class="btn-tbf white" @click="deleteTrigger(index)"><icon-trash /></button>
									</div>
								</div>
								<div class="level-skill">
									<span v-if="['date'].includes(trigger.type)">{{ $t(`trigger.value.${trigger.type}`, {value: trigger.value}) }}</span>
									<span v-else-if="['procedure', 'process'].includes(trigger.type)">{{ trigger.entity_name }}</span>
									<span v-else-if="['weekly'].includes(trigger.type)">{{ trigger.value.split(",").map(el => { return $t(`calendar.long_days.${el}`) }).join(', ') }}</span>
									<span v-else>{{ trigger.value }}</span>
								</div>
							</div>
						</div>
					</div>

					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{$t('procedures.triggers')}}</label>
							<div v-if="$v.selectedTrigger.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box bg-white" v-bind:class="{has_error: $v.selectedTrigger.$error}">
							<div class="icon-left"><icon-trigger /></div>
							<div class="icon-right" v-if="selectedTrigger" @click.stop="clearTriggerForm()"><icon-close class="icon-clear" /></div>
							<multiselect 
								class="hide-tags"
								v-bind:class="{populate: selectedTrigger}"
								v-model="selectedTrigger"
								:options="optionsTrigger"
								:allow-empty="false"
								:show-labels="false"
								:multiple="false" 
								:close-on-select="true"
								track-by="id" 
								label="name"
								@input="$v.$reset()"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{$t('procedures.choose_trigger')}}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('create-tags.selected-users') }}</span></template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>

							</multiselect>
						</div>
					</div>
					<div class="input-group input-group-btn w-50" v-if="$resize && $mq.above(600)">
						<div class="add-more-btn">
							<button v-if="!isEdit" class="btn-tbf blue" @click="addTrigger">
								<span class="text">{{$t('procedures.add_trigger')}}</span>
							</button>
							<button v-else class="btn-tbf blue" @click="updateTrigger">
								<span class="text">{{$t('procedures.update_trigger')}}</span>
							</button>
						</div>
					</div>
					<div class="input-group w-100" v-if="selectedTrigger.value == 'process'">
						<div class="label-header">
							<label class="label">{{$t('processes.processes')}}</label>
							<div v-if="$v.selectedProcess.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedProcess.$error}">
							<div class="icon-left"><icon-procedures /></div>
							<div class="icon-right" v-if="selectedProcess.length" @click.stop="selectedProcess = []"><icon-close class="icon-clear" /></div>
							<multiselect 
								class="hide-tags"
								v-bind:class="{populate: selectedProcess.length}"
								v-model="selectedProcess"
								:options="processes"
								:allow-empty="true"
								:show-labels="false"
								:multiple="false" 
								:close-on-select="true"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{$t('processes.choose_process')}}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group w-100" v-if="selectedTrigger.value == 'procedure'">
						<div class="label-header">
							<label class="label">{{$t('procedures.procedures')}}</label>
							<div v-if="$v.selectedProcedure.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedProcedure.$error}">
							<div class="icon-left"><icon-procedures /></div>
							<div class="icon-right" v-if="selectedProcedure != ''" @click.stop="selectedProcedure = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								class="hide-tags"
								v-bind:class="{populate: selectedProcedure != ''}"
								v-model="selectedProcedure"
								:options="procedures"
								:allow-empty="true"
								:show-labels="false"
								:multiple="false" 
								:close-on-select="true"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{$t('procedures.choose_procedure')}}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
					<div v-if="selectedTrigger.value == 'date'" class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{$t('procedures.select_date')}}</label>
							<div v-if="$v.repetitive_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box br-white" v-bind:class="{has_error: $v.repetitive_date.$error}">
							<div class="select-workdays">
								<div class="checkbox-workdays" v-for="day in optionsDates">
									<span @click="selectDayOfMonth(day)" class="checkmark" v-bind:class="{active: repetitive_date == day}">{{day}}</span>
								</div>
							</div>
						</div>
					</div>
					<div v-if="selectedTrigger.value == 'weekly'" class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{$t('procedures.select_day')}}</label>
							<div v-if="$v.weekly.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box br-white" v-bind:class="{has_error: $v.weekly.$error}">
							<div class="select-workdays weekly">
								<div class="checkbox-workdays" v-for="day in days">
									<span @click="selectDay(day)" class="checkmark" v-bind:class="{active: weekly.includes($t('calendar.small_days.' + day.toLowerCase()))}">{{day}}</span>
								</div>
							</div>
						</div>
					</div>
					<div v-if="selectedTrigger.value == 'event'" class="input-group w-100">
						<div class="input-box bg-white" v-bind:class="{has_error: $v.event_description.$error}">
							<textarea rows="3" :placeholder="$t('procedures.event_placeholder')" v-model="event_description"></textarea>
						</div>
					</div>
					<div v-if="selectedTrigger.value == 'yearly'" class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('procedures.select_date')}}</label>
							<div v-if="$v.repetitive_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box">
							<FunctionalCalendar
							ref="CalendarDateOfEmployment"
							v-model="dateOfYear"
							class="calendar-tbf frequency"
							:is-multiple-date-picker="true"
							:is-multiple="true"
							:calendars-count="3"
							:is-separately="false"
							:change-month-step="3"
							:hidden-elements="['leftAndRightDays']"
							:limits="limitsRange"
							@dayClicked="selectDayOfYear"
							>
								<template v-slot:datePickerInput="props">
									<input
									class="vfc-single-input custom-input-picker"
									type="text"
									:value="props.selectedDate ? moment(props.selectedDate, 'YYYY-M-D').format('DD MMMM') : ''" 
									v-if="Object.keys(dateOfYear).length"/>

								</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group input-group-btn w-100" v-if="$resize && $mq.below(599)">
						<div class="add-more-btn">
							<button v-if="!isEdit" class="btn-tbf blue" @click="addTrigger">
								<span class="text">{{$t('procedures.add_trigger')}}</span>
							</button>
							<button v-else class="btn-tbf blue" @click="updateTrigger">
								<span class="text">{{$t('procedures.update_trigger')}}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg-settings" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$router.push({name: 'processes'})"><span class="text">{{$t('general.cancel')}}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!process.id">
				<div class="loader"></div>
				<span class="text">{{$t('processes.new_process')}}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{$t('processes.update_process')}}</span>
			</button>
		</div>
	</div>
	<loader-users-create  v-else/>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
	import IconPeople from '../../Icons/People'
	import IconChart from '../../Icons/Chart'
	import IconTrigger from '../../Icons/Trigger'
	import IconProcedures from '../../Icons/Procedures'
	import IconCalendar from '../../Icons/Date'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconDate from '../../Icons/Date'
	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required, requiredIf } from 'vuelidate/lib/validators'
	import IconQuestion from '../../Icons/QuestionHelp'

	export default {
		components: {
			IconArrowBack,
			IconClose,
			IconPeople,
			IconChart,
			IconTrigger,
			IconProcedures,
			IconCalendar,
			IconEdit, 
			IconTrash,
			IconDate,
			LoaderUsersCreate,
			IconQuestion
		},
		data() {
			return {
				loaded: false,
				process: {
					name: '',
					description: '',
				},
				error_message: '',
				users: [],
				selectedUser: '',
				optionsDates: Array.from({length: 31}, (_, i) => i + 1),
				optionsTrigger:[
					{ name: this.$t('trigger.other_process'), value: 'process', id: 1 },
					{ name: this.$t('trigger.other_procedure'), value: 'procedure', id: 2 },
					{ name: this.$t('trigger.an_event'), value: 'event', id: 3 },
					{ name: this.$t('trigger.a_date'), value: 'date', id: 4 },
					{ name: this.$t('trigger.weekly_date'), value: 'weekly', id: 6 },
					{ name: this.$t('trigger.a_yearly_date'), value: 'yearly', id: 7}
				],
				selectedTrigger: '',
				procedures: [],
				selectedProcedure: '',
				event_description: '',
				repetitive_date: '',
				savedTriggers: [],
				indexTrigger: '',
				isEdit: false,
				triggerValidation: false,
				saveValidations: false,
				processes: [],
				selectedProcess: [],
				days :[this.$t('calendar.small_days.sunday'), this.$t('calendar.small_days.monday'), this.$t('calendar.small_days.tuesday'), this.$t('calendar.small_days.wednesday'), this.$t('calendar.small_days.thursday'), this.$t('calendar.small_days.friday'), this.$t('calendar.small_days.saturday')],
				weekly: [],
				yearly: [],
				errorsBe: {},
				helpData: this.$store.getters['help_links/currentLink'](this.$route.name),
				dateOfYear: {},
				limitsRange: {min: '', max: ''},
				newStartDay: ''
			};
		},
		created(){
			this.limitsRange.min = moment().startOf('year').format('D/M/YYYY')
			this.limitsRange.max = moment().endOf('year').format('D/M/YYYY')
		},
		async mounted(){
			await this.getFilters();

			if(this.$route.params.slug){
				await this.getProcessData()
			}else{
				setTimeout(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				}, 0)
			}
		},
		validations() {
			let validatorObj = {
				process: {
					name: {required: false},
				},
				selectedUser: {required: false},
				selectedTrigger: {required: false},
				selectedProcess: {required: false},
				selectedProcedure: {required: false},
				event_description: {required: false},
				repetitive_date: {required: false},
				weekly: {required: false},
				yearly: {required: false}

			}

			if(this.saveValidations){
				validatorObj.process.name = {required}
				validatorObj.selectedUser = {required}
			}

			if(this.triggerValidation) {
				validatorObj.selectedTrigger = {required}
				validatorObj.selectedProcedure = { required: requiredIf( function() { return this.selectedTrigger.value == 'procedure' }) }
				validatorObj.selectedProcess = { required: requiredIf( function() { return this.selectedTrigger.value == 'process' }) }
				validatorObj.event_description = { required: requiredIf( function() { return this.selectedTrigger.value == 'event'}) }
				validatorObj.repetitive_date = { required: requiredIf( function() { return this.selectedTrigger.value == 'date'}) }
				validatorObj.weekly = { required: requiredIf( function() { return this.selectedTrigger.value == 'weekly'}) }
				validatorObj.yearly = { required: requiredIf( function() { return this.selectedTrigger.value == 'yearly'}) }
			}

			return validatorObj
		},
		methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {procedures: true, users: true, processes: true }})
				.then(({data}) => {
        			this.procedures = data.data.procedures
					this.processes = data.data.processes
        			this.users = data.data.users
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getProcessData(){
				await axios.get(`/processes/${this.$route.params.slug}/edit`)
				.then(({data}) => {
					let processData = data.data
					this.process = processData
					this.savedTriggers = processData.triggers
					if(processData.responsible){
						this.selectedUser = this.users.find(el => el.id == processData.responsible.id)
					}
				})
				.catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			getValue(value) {
				var filtered = this.procedures.filter((item) => item.id == value)
				return filtered.map(el => el.name).join(', ')
			},
			formatDate(date) {
				var localDate = this.$moment(date).local();
				var splitStr = localDate.format("LL").toLowerCase().split(' ');
				for (var i = 0; i < splitStr.length; i++) {
					splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
				}
				return splitStr.join(' ');
			},
			addTrigger(){
				this.saveValidations = false
				this.triggerValidation = true
				this.$v.$touch()

				if(!this.$v.$invalid){
					let triggerObj = {}
					switch (this.selectedTrigger.value){
						case 'procedure':
							triggerObj.type = this.selectedTrigger.value
							triggerObj.value = this.selectedProcedure.id
							triggerObj.entity_name = this.selectedProcedure.name
							break;
						case 'process':
							triggerObj.type = this.selectedTrigger.value
							triggerObj.value = this.selectedProcess.id
							triggerObj.entity_name = this.selectedProcess.name
							break;
						case 'event':
							triggerObj.type = this.selectedTrigger.value
							triggerObj.value = this.event_description
							break;
						case 'date':
							triggerObj.type = this.selectedTrigger.value
							triggerObj.value = this.repetitive_date
							break;
						case 'weekly':
							triggerObj.type = this.selectedTrigger.value
							triggerObj.value = this.weekly.join(",")
							break;
						case 'yearly':
							triggerObj.type = this.selectedTrigger.value
							triggerObj.value = this.yearly.join(", ")
							break;
					}

					this.savedTriggers.push(triggerObj)
					this.triggerValidation = false
					this.clearTriggerForm();
					this.$v.$reset()
					this.dateOfYear = {}
				}
			},
			editTrigger(trigger, index){
				this.isEdit = true
                this.triggerSelected = trigger;
				this.indexTrigger = index

				switch (trigger.type){
					case 'procedure':
					this.trigger_name = trigger.name
                    this.selectedTrigger = { name: this.$t('trigger.other_procedure'), value: 'procedure', id: 2 }
                    this.selectedProcedure = this.procedures.find(el => el.id == trigger.value)
					break;
					case 'process':
					this.trigger_name = trigger.name
                    this.selectedTrigger = { name: this.$t('trigger.other_process'), value: 'process', id: 1 }
                    this.selectedProcess = this.processes.find(el => el.id == trigger.value)
					break;
					case 'event':
					this.trigger_name = trigger.name
                    this.selectedTrigger = { name: this.$t('trigger.an_event'), value: 'event', id: 3 }
                    this.event_description = trigger.value
					break;
					case 'date':
					this.trigger_name = trigger.name
                    this.selectedTrigger = { name: this.$t('trigger.a_date'), value: 'date', id: 4 }
					this.repetitive_date = trigger.value
					break;
					case 'weekly':
					this.trigger_name = trigger.name
                    this.selectedTrigger = { name: this.$t('trigger.weekly_date'), value: 'weekly', id: 6 }
					this.weekly = trigger.value.split(",")
					break;
					case 'yearly':
					this.trigger_name = trigger.name
                    this.selectedTrigger = { name: this.$t('trigger.yearly_date'), value: 'yearly', id: 7 }
					this.yearly = trigger.value.split(", ")
					this.dateOfYear = {
								selectedDate: false,
								selectedDateTime: false,
								multipleDateRange: [],
								selectedDatesItem: "",
								selectedHour: "00",
								selectedMinute: "00",
								dateRange: {end: '',start: ''},
								selectedDates: this.yearly.map(function(a) { return {date: moment(a, 'DD MMMM').format('D/M/YYYY'), dateTime: false, hour: "00", minute: "00"} })
							}
					break;
				}
			},
			deleteTrigger(index) {
				this.savedTriggers.splice(index, 1);
			},
			updateTrigger() {
				this.saveValidations = false
				this.triggerValidation = true
				this.$v.$touch()

				if(!this.$v.$invalid){
					let triggerObj = {}
					triggerObj.type = this.selectedTrigger.value

					switch (this.selectedTrigger.value){
						case 'procedure':
						triggerObj.value = this.selectedProcedure.id
						triggerObj.entity_name = this.selectedProcedure.name
						break;
						case 'process':
						triggerObj.value = this.selectedProcess.id
						triggerObj.entity_name = this.selectedProcess.name
						break;
						case 'event':
						triggerObj.value = this.event_description
						break;
						case 'date':
						triggerObj.value = this.repetitive_date
						break;
						case 'weekly':
						triggerObj.value = this.weekly.join(",")
						break;
						case 'yearly':
						triggerObj.value = this.yearly.join(", ")
						break;
					}

					this.savedTriggers[this.indexTrigger] = triggerObj
					this.triggerValidation = false
					this.clearTriggerForm();
					this.$v.$reset()
					this.dateOfYear = {}
				}
			},
			clearTriggerForm() {
				this.selectedTrigger = ''
				this.selectedProcedure = ''
				this.selectedProcess = ''
                this.event_description = ''
                this.repetitive_date = ''
                this.weekly = []
				this.yearly = []
				this.triggerValidation = false
				this.isEdit = false
			},
			saveAction(type){
				this.saveValidations = true
				if(this.selectedTrigger){this.triggerValidation = true}
				
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				
				this.error_message = ''

				this.$v.$touch()

				if(!this.$v.$invalid){
					if(this.selectedTrigger){
						let triggerObj = {}
						switch (this.selectedTrigger.value){
							case 'procedure':
								triggerObj.type = this.selectedTrigger.value
								triggerObj.value = this.selectedProcedure.id
								triggerObj.entity_name = this.selectedProcedure.name
								break;
							case 'process':
								triggerObj.type = this.selectedTrigger.value
								triggerObj.value = this.selectedProcess.id
								triggerObj.entity_name = this.selectedProcess.name
								break;
							case 'event':
								triggerObj.type = this.selectedTrigger.value
								triggerObj.value = this.event_description
								break;
							case 'date':
								triggerObj.type = this.selectedTrigger.value
								triggerObj.value = this.repetitive_date
								break;
							case 'weekly':
								triggerObj.type = this.selectedTrigger.value
								triggerObj.value = this.weekly.join(',')
								break;
							case 'yearly':
								triggerObj.type = this.selectedTrigger.value
								triggerObj.value = this.yearly.join(', ')
								break;
						}

						if(this.isEdit){
							this.savedTriggers[this.indexTrigger] = triggerObj
						}else{
							this.savedTriggers.push(triggerObj)
						}

						this.triggerValidation = false
						this.clearTriggerForm();
					}

					var savedTriggers = this.savedTriggers.length ? this.savedTriggers.map(el => {return {id:el.id, type: el.type, value: el.value}}) : ''

					let objData = {}

					objData.name = this.process.name
					objData.description = this.process.description
					objData.responsible_id = this.selectedUser.id
					objData.triggers = savedTriggers

					if(type == 'create'){
						this.createProcess(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateProcess(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('processes.new_process') : this.$t('processes.update_process')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createProcess(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/processes/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('processes.new_process')
							setTimeout(()=>{
								this.$auth.fetch();
								this.$router.push({name: 'process-show', params:{ slug: data.slug }})
							}, 500)
						}, 1000)
					}, 300)
				}).catch(error =>{
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			updateProcess(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/processes/${this.process.slug}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('processes.update_process')
							setTimeout(()=>{
								this.$auth.fetch();
								this.$router.push({name: 'processes'})
							}, 500)
						}, 1000)
					}, 300)
				})
				.catch(error =>{
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			selectDayOfMonth(day) {
				this.repetitive_date = day
			},
			selectDay(day) {
				switch (day) {
					case this.$t('calendar.small_days.monday'):
						if (this.weekly.includes('monday')) {
							this.weekly.splice(this.weekly.indexOf('monday'), 1)
						} else {
							this.weekly.push('monday')
						}
						break;
					case this.$t('calendar.small_days.tuesday'):
						if (this.weekly.includes('tuesday')) {
								const index = this.weekly.indexOf('tuesday')	
								this.weekly.splice(index, 1)
						} else {
							this.weekly.push('tuesday')
						}
						break;
					case this.$t('calendar.small_days.wednesday'):
						if (this.weekly.includes('wednesday')) {
								const index = this.weekly.indexOf('wednesday')	
								this.weekly.splice(index, 1)
						} else {
							this.weekly.push('wednesday')
						}
						break;
					case this.$t('calendar.small_days.thursday'):
						if (this.weekly.includes('thursday')) {
								const index = this.weekly.indexOf('thursday')	
								this.weekly.splice(index, 1)
						} else {
							this.weekly.push('thursday')
						}
						break;
					case this.$t('calendar.small_days.friday'):
						if (this.weekly.includes('friday')) {
								const index = this.weekly.indexOf('friday')	
								this.weekly.splice(index, 1)
						} else {
							this.weekly.push('friday')
						}
						break;
					case this.$t('calendar.small_days.saturday'):
						if (this.weekly.includes('saturday')) {
								const index = this.weekly.indexOf('saturday')	
								this.weekly.splice(index, 1)
						} else {
							this.weekly.push('saturday')
						}
						break;
					case this.$t('calendar.small_days.sunday'):
						if (this.weekly.includes('sunday')) {
								const index = this.weekly.indexOf('sunday')	
								this.weekly.splice(index, 1)
						} else {
							this.weekly.push('sunday')
						}
						break;
					default:
						break;
				}
			},
			selectDayOfYear(day){
				var date = day.hasOwnProperty('date') ? moment(day.date, 'D/M/YYYY').format('DD MMMM') : day;

				if(this.yearly.includes(date)) {
					this.yearly.splice(this.yearly.findIndex(el => el == date), 1)
				} else {
					this.yearly.push(date)
				}
			}
		}
	};
</script>
